import {
  type ClientLoaderFunctionArgs,
  useLoaderData,
  useNavigate,
} from '@remix-run/react';

import { EnumsPromptTemplateType } from '@lp-lib/api-service-client/public';

import { PromptTemplateEditor } from '../components/PromptTemplate/PromptTemplateEditor';
import { PromptTemplateUtils } from '../components/PromptTemplate/utils';
import { useTitle } from '../hooks/useTitle';
import { AdminView } from '../pages/Admin/AdminView';
import { AdminToolkitNav } from '../pages/Admin/Toolkit';
import { makeTitle } from '../utils/common';

export const loader = async (action: ClientLoaderFunctionArgs) => {
  const url = new URL(action.request.url);
  const type = url.searchParams.get('type');
  return { type };
};

export function Component() {
  const { type } = useLoaderData<typeof loader>();
  const navigate = useNavigate();

  useTitle(makeTitle('Create Prompt Template'));

  return (
    <AdminView className='bg-library-2023-07 p-10 flex flex-col gap-10'>
      <AdminToolkitNav />
      <PromptTemplateEditor
        template={null}
        type={
          PromptTemplateUtils.ValidType(type)
            ? type
            : EnumsPromptTemplateType.PromptTemplateTypeSimple
        }
        initialEditing
        onSave={() => {
          navigate('..');
        }}
        onCancel={() => {
          navigate('..');
        }}
      />
    </AdminView>
  );
}
